import React, { Component } from "react";
import { Link } from "@reach/router";

import "../pages/New-Game.css";
import { get, post } from "../../utilities.js";
import { socket } from "../../client-socket.js";

// prop: game: the game object
class GraderPage extends Component {
  constructor(props) {
    super(props);
    this.state = { questionNum: 0, partNum: 0, answerObjects: [], grades: [] };
    console.log("HERE" + `grades${this.props.game.gameCode}`);
    socket.on(`grades${this.props.game.gameCode}`, (results) => {
      // console.log("HERE 1");
      // console.log(results);
      // console.log(results.questionNum);
      if (results.questionNum - 1 === this.state.questionNum) {
        // console.log("HERE 2");
        let objectsArray = [...this.state.answerObjects];
        console.log(objectsArray);
        let relevantAnswer = this.state.answerObjects.filter((answer) => {
          return answer.team === results.teamName;
        })[0];
        let relevantIndex = this.state.answerObjects.indexOf(relevantAnswer);
        relevantAnswer.grade = results.newGrades;
        objectsArray[relevantIndex] = relevantAnswer;
        console.log(relevantAnswer);
        console.log(objectsArray);
        let grades = [...this.state.grades];
        grades[relevantIndex] = results.newGrades;

        this.setState({ answerObjects: objectsArray, grades: grades });
      }
    });
  }

  componentDidMount = () => {
    this.handleQuestionChange(0, 0);
  };

  handleQuestionChange = (e, p) => {
    let questionNum, partNum;
    if (typeof e === 'number') {
      questionNum = e;
      partNum = p;
    } else {
      let parts = e.target.value.split('/');
      questionNum = parseInt(parts[0]);
      partNum = parseInt(parts[1]);
    }
    console.log('Switching question', questionNum, partNum);
    if (questionNum < 0 || questionNum >= this.props.game.questions.length) {
      alert("That's not a valid question number.");
      return;
    }
    get("/api/answers/", { gameCode: this.props.game.gameCode, questionNum: questionNum + 1 }).then(
      (results) => {
        console.log("found some results");
        console.log(results);
        let grades = results.map((answer) => {
          if (answer.grade.length === 0) {
            return new Array(this.props.game.questions[questionNum].length).fill("Not graded");
          }
          return answer.grade;
        });
        console.log("Here are the grades I found:");
        console.log(grades);
        this.setState({ questionNum: questionNum, partNum: partNum, answerObjects: results, grades: grades });
      }
    );
  };

  handleAnswerInput = (answer, grade, teamIndex, partNum) => {
    const maximum = this.props.game.points[this.state.questionNum][partNum];
    if (grade < -maximum || grade > maximum) {
      alert(
        "That is not a valid score. The max is " + maximum + ", and the minimum is -" + maximum
      );
      return;
    }
    let oldgrade = this.state.grades[teamIndex][partNum]
    if ((oldgrade === null || oldgrade === '') && grade === '1') {
      grade = 0;
    }
    console.log(
      `read in a grade of ${grade} for part ${partNum} for the answer for team ${answer.team}`
    );
    // gameCode, questionNum, partNum, teamName, grade;
    post("/api/grades/", {
      gameCode: answer.gameCode,
      questionNum: answer.questionNumber,
      teamName: answer.team,
      partNum: partNum,
      grade: grade,
      numParts: this.props.game.questions[answer.questionNumber - 1].length,
    });
    let newGrades = this.state.grades;
    newGrades[teamIndex][partNum] = grade;
    this.setState({ grades: newGrades });
  };

  validGrade(value, defaultValue) {
    defaultValue = defaultValue || '';
    if (value === null || value === '') {
      return defaultValue;
    } else {
      return value;
    }
  }

  render() {
    let partNum = this.state.partNum;
    const theAnswers = this.state.answerObjects.map((answerObj, i) => {
      return (
          <div key={`answerObject-${partNum}-${i}`}>
            <p className='question'>{this.props.game.questions[this.state.questionNum][this.state.partNum]}</p>
            <h4>Answer:</h4>
            <textarea readOnly={true} rows="5" cols="80" className="large-text-box"
                      value={answerObj.content[partNum] || '<no answer provided>'} />
            <p>
              Current grade:{" "}
              {this.validGrade(this.state.grades[i][partNum], 'Not graded')}
            </p>
            <input
              className="small-text-box"
              type="number"
              value={this.validGrade(this.state.grades[i][partNum], '')}
              onChange={e => this.handleAnswerInput(answerObj, e.target.value, i, partNum)} />
          </div>
      );
    });
    return (
      <>
        <h1>
          {this.props.game.name}: {this.props.game.gameCode}
        </h1>
        <p>Choose Question:&nbsp;
        <select onChange={this.handleQuestionChange} value={this.state.questionNum.toString(10) + '/' + this.state.partNum}>
          {this.props.game.questions.map((q, i) => {
            return q.map((p, j) => {
              return <option key={`${i}/${j}`} value={`${i}/${j}`}>Question {String.fromCharCode(65 + i)} Part {j + 1}</option>
            })
          })}
        </select>
        </p>
        <h2>Question {String.fromCharCode(this.state.questionNum + "A".charCodeAt(0))} - Part {this.state.partNum + 1}</h2>
        {theAnswers}
        <p>
          Grade another question?&nbsp;
          <select onChange={this.handleQuestionChange} value={this.state.questionNum.toString(10) + '/' + this.state.partNum}>
            {this.props.game.questions.map((q, i) => {
              return q.map((p, j) => {
                return <option key={`${i}/${j}`} value={`${i}/${j}`}>Question {String.fromCharCode(65 + i)} Part {j + 1}</option>
              })
            })}
          </select>
        </p>
        <div className="u-flex-justifyCenter top-margin">
          <span className="NewGame-button" onClick={this.handleSubmit}>
            <a className="button-text" href="/admin/">
              Finished Grading
            </a>
          </span>
        </div>
      </>
    );
  }
}

export default GraderPage;
