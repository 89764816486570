import React, { Component } from "react";
import ProctorReset from "./ProctorReset.js";

import { get, post } from "../../utilities.js";

import { socket } from "../../client-socket.js";

import '../../utilities.css';

// prop: game: the game object
class ProctorPage extends Component {
  constructor(props) {
    super(props);
    // Initialize Default State
    this.state = { answerObjects: [] };

    //`startQ:${gameCode}`, { team: teamName, time: currTime }
    socket.on(`startQ:${this.props.game.gameCode}`, (data) => {
      let answers = this.state.answerObjects;
      answers.push({ questionNumber: data.questionNum, startTime: data.time, team: data.team });
      this.setState({ answers });
    });
  }

  componentDidMount = () => {
    get("/api/start-times/", { gameCode: this.props.game.gameCode }).then((results) => {
      console.log("found some results");
      this.setState({ answerObjects: results });
    });
  };

  handleTeamSelection(e) {
    this.setState({
      team: e.target.value
    });
  }

  resetTeam() {
    let teams;
    if (this.state.team === '__all__') {
      teams = this.props.game.teams;
    } else {
      teams = [this.state.team];
    }
    post('/api/reset-team', {
      gameCode: this.props.game.gameCode,
      password: this.props.game.adminPassword,
      teams: teams
    }).then(() => {
      this.setState({
        resetMessage: this.state.team === '__all__' ? 'Teams reset' : 'Team reset'
      });
    })
  }


  // gameCode: String,
  //   questionNumber: Number,
  //   team: String,
  //   content: [String],
  //   startTime: String,
  render() {
    let messageDiv = ''
    if (this.state.resetMessage) {
      messageDiv = (<div className="info">{this.state.resetMessage}</div> )
    }
    return (
      <>
        <h1>
          {this.props.game.name}: {this.props.game.gameCode}
        </h1>
        <div className="button-holder login-div">
          <select className="dropDown" onChange={(e) => this.handleTeamSelection(e)}>
            <option>--- Pick a team ---</option>
            <option value={'__all__'}>RESET ALL TEAMS</option>
            {this.props.game.teams.map(team => {
              return (
                  <option key={team} value={team}>{team}</option>
              )
            })}
          </select>
          <span className="button" onClick={(e) => this.resetTeam(e)}>
            <span className="button-text">Reset Team State</span>
          </span>
          {messageDiv}
        </div>
        {this.state.answerObjects.map((answerObj, i) => (
          <div key={`answerObject-${i}`}>
            <h2> {answerObj.team} </h2>
            <p>
              Started Question{" "}
              {String.fromCharCode(answerObj.questionNumber - 1 + "A".charCodeAt(0))} at{" "}
              {answerObj.startTime}{" "}
            </p>
          </div>
        ))}

        <ProctorReset gameCode={this.props.game.gameCode} teams={this.props.game.teams} />
      </>
    );
  }
}

export default ProctorPage;
