import React from "react";
import "../../utilities.css";

const PUBLIC_URL = "";

// PROPS:
// gameCode
// questionNum
class ImageDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  formatURL = (extension) => {
    if (extension.includes("public/")) {
      extension = extension.substr(extension.indexOf("/"));
    }
    return PUBLIC_URL + extension;
  };

  render() {
    return (
      <div>
        {this.props.images.map((image, i) => (
          <img
            key={`${this.props.gameCode}IMAGE${i}`}
            alt={`Image Number ${i}`}
            src={this.formatURL(image.img.path)}
          />
        ))}{" "}
      </div>
    );
  }
}

export default ImageDisplay;
