import React, { Component } from "react";
import "../../utilities.css";
import "../pages/LoginPages.css";

import { socket } from '../../client-socket';

// props:
// name
// password
// questionNum
// time
// onSuccess
class QuestionLogin extends Component {
  constructor(props) {
    super(props);
    // Initialize Default State
    this.state = {
      inputedPassword: "",
      numPlayers: 1
    };
    socket.on('roomupdated', e => {
      this.setState({
        numPlayers: e.numPlayers
      })
    });
    socket.on('connect', () => {
      socket.emit('joinroom', {gameCode: props.gameCode, teamName: props.teamName})
    })
    socket.emit('joinroom', {gameCode: props.gameCode, teamName: props.teamName})
  }

  handleSubmit = (event) => {
    if (this.state.inputedPassword === this.props.password) {
      this.props.onSuccess();
    } else {
      alert("That password was incorrect");
    }
  };

  handleChangePassword = (event) => {
    // to UpperCase()
    event.target.value = event.target.value.toUpperCase();
    this.setState({
      inputedPassword: event.target.value,
    });
  };

  render() {
    return (
      <>
        <h1 className="title">
          {this.props.name}: Question{" "}
          {String.fromCharCode(this.props.questionNum - 1 + "A".charCodeAt(0))}
        </h1>
        <h3 className="standard-text u-textCenter"> Team: {this.props.teamName} </h3>
        <h3 className="standard-text u-textCenter"> Time: {this.props.time} minutes </h3>
        <div className="button-holder login-div">
          <div className="login-div">
            <input
              className="login-textbox"
              type="text"
              placeholder="question password"
              onChange={this.handleChangePassword}
            />
          </div>
          <p className={"warning"}>
            Warning: Do not click submit until all team members are at this screen,<br/>
            otherwise they will be locked out of this round.</p>
          <p className={"warning"} style={{color: 'black'}}>Team Members Present: {this.state.numPlayers}</p>
          <span className="button" onClick={this.handleSubmit}>
            <span className="button-text">Submit </span>
          </span>
        </div>
      </>
    );
  }
}

export default QuestionLogin;
