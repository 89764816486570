import React, { Component } from 'react';
import * as StringBinding from 'sharedb-string-binding';

export default class SynchronizedTextArea extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log('componentDidMount: resetting')
    this.el.value = '';
    let cb = err => {
      if (err) throw err;
      this.binding = new StringBinding(this.el, this.props.doc, [`part-${this.props.part}`]);
      this.binding.setup();
    }
    if (this.props.doc.subscribed) {
      cb();
    } else {
      this.props.doc.subscribe(cb);
    }
  }

  componentWillUnmount() {
    this.props.doc.flush();
    this.binding.destroy();
    this.el.value = '';
  }

  render() {
    return (
        <textarea ref={el => this.el = el} rows="5" cols="80" className="large-text-box"/>
    )
  }
}