import React, { Component } from "react";

import StudentLogin from "./Student-Login.js";
import QuestionPage from "./QuestionPage.js";
import { socket } from "../../client-socket";

import "../../utilities.css";
import { get, post } from "../../utilities.js";
import { navigate } from "@reach/router";

import { default as WebSocket } from 'reconnecting-websocket';
import { Connection } from 'sharedb/lib/client';

class StudentPages extends Component {
  constructor(props) {
    super(props);
    // Initialize Default State
    this.state = {
      game: "", // this is a game object
      teamName: "",
      teams: [],
      studentName: "",
      onQuestion: 0,
      done: false,
    };
  }

  submitGameCode = (code) => {
    console.log(code);
    get("/api/game-info", { gameCode: code }).then((results) => {
      console.log("got the game");
      this.setState({ game: results, teams: results.teams });
    });
  };

  getDoc(gameCode, teamName, question) {
    let proto = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    let gameId = gameCode + ':' + teamName + ':' + question;
    let conn = new Connection(new WebSocket(proto + window.location.host + '/answer/?id=' + gameId));
    let doc = conn.get('answers', gameId);
    return {conn: conn, doc: doc};
  }

  submitTeamName = (teamName) => {
    let newState = {teamName: teamName};
    if (!this.state.doc) {
      let {conn, doc} = this.getDoc(this.state.game.gameCode, teamName, 0);
      newState.conn = conn;
      newState.doc = doc;
    }
    this.setState(newState);
  };

  setTeamName = (teamName) => {
    this.setState({ teamName: teamName });
    console.log(teamName);
  };

  nextQuestion = () => {
    if (this.state.onQuestion + 1 === this.state.game.questions.length) {
      this.setState({ done: true });
      console.log("SETTING DONE TO TRUE");
    } else {
      if (this.state.conn && this.state.conn instanceof Connection) {
        this.state.conn.close();
      }
      let {conn, doc} = this.getDoc(this.state.game.gameCode, this.state.teamName, this.state.onQuestion + 1);
      this.setState({
        onQuestion: this.state.onQuestion + 1,
        conn: conn,
        doc: doc
      });
    }
  };

  onReset() {
    this.state.doc.unsubscribe();
    this.state.game.questions.forEach((q, i) => {
      q.forEach((p, j) => {
        this.state.doc.data[`part-${j}`] = '';
      })
    })
    this.setState({
      game: '',
      teamName: ''
    });
    // Clear out the existing state entirely otherwise we have multiple
    // living documents and it causes problems.
    window.location.reload();
  }

  render() {
    if (this.state.game === "" || this.state.teamName === "") {
      return (
        <>
          <StudentLogin
            submitGameCode={this.submitGameCode}
            submitTeamName={this.submitTeamName}
            teams={this.state.teams}
          />
        </>
      );
    }
    let i = this.state.onQuestion;
    if (this.state.done) {
      return <> You're done with the game! </>;
    }
    return (
      <QuestionPage
        gameCode={this.state.game.gameCode}
        name={this.state.game.name}
        questionNumber={1 + i}
        doc={this.state.doc}
        teamName={this.state.teamName}
        parts={this.state.game.parts[i]}
        questions={this.state.game.questions[i]}
        hideQuestions={!!this.state.game.hideQuestions}
        nextQuestionTime={this.state.game.times[i + 1]}
        time={this.state.game.times[i] * 60}
        points={this.state.game.points[i]}
        password={this.state.game.questionPasswords[i]}
        nextQuestion={this.nextQuestion}
        onReset={() => this.onReset()}
      />
    );
  }
}

export default StudentPages;
