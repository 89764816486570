import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App.js";

document.body.addEventListener('copy', e => {
  if (window.location.href.indexOf('/student/') >= 0) {
    if (e.target.tagName === 'TEXTAREA') {
      return;
    }
    e.preventDefault();
    return false;
  }
});

// renders React Component "Root" into the DOM element with ID "root"
ReactDOM.render(<App />, document.getElementById("root"));

// allows for live updating
module.hot.accept();

function type(target, message, repeat) {
  let i = 0;
  repeat = repeat || 5;
  let actor = () => {
    if (i < message.length) {
      let nextChar = message.charAt(i);
      let insertionPoint = target.selectionStart;
      target.value = target.value.substring(0, insertionPoint) + nextChar + target.value.substring(insertionPoint);
      target.selectionEnd = target.selectionStart = insertionPoint + 1;
      target.dispatchEvent(new InputEvent('input'));
      i++;
      setTimeout(actor, 200 + (Math.random() - 0.5) * 200);
    } else if (repeat > 0) {
      repeat--;
      i = 0;
      actor();
    }
  };
  setTimeout(actor, 200 + (Math.random() - 0.5) * 200);
}
window.typeMessage = type;
