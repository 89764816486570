import React, {Component} from "react";
import {post} from "../../utilities";

class CloneGamePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalGameCode: this.props.game.gameCode,
      name: this.props.game.name,
      numQuestions: this.props.game.questions.length,
      parts: this.props.game.questions.map(question => question.length),
      questions: this.props.game.questions,
      hideQuestions: !!this.props.game.hideQuestions,
      times: this.props.game.times,
      points: this.props.game.points,
      submitted: false,
      teams: this.props.game.teams,
      images: this.props.game.images,
      gameCode: this.makePassword(),
      adminPassword: this.makePassword(),
      questionPasswords: this.props.game.questionPasswords
    };
  }

  makePassword = (characters = "ABCDEFGHJKLMNPQRSTUVWXYZ0123456789") => {
    let result = "";
    const charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  handleChangeTeams = (event) => {
    // console.log(event.target.value);
    let teams = event.target.value.split("\n");
    // console.log(teams);
    this.setState({ teams: teams });
  };

  handleSubmit = (event) => {
    if (this.state.teams.length === 0) {
      alert("Input the teams that will be in this game");
      return;
    }
    let questionPasswords = this.state.questionPasswords.slice(0, this.state.numQuestions);
    for (let i = questionPasswords.length; i < this.state.numQuestions; i++) {
      questionPasswords[i] = this.makePassword('ABCDEFGHJKLMNPQRSTUVWXY');
    }
    post("/api/new-game/", {
      gameCode: this.state.gameCode,
      name: this.state.name,
      parts: this.state.parts,
      questions: this.state.questions,
      hideQuestions: !!this.state.hideQuestions,
      times: this.state.times,
      points: this.state.points,
      questionPasswords: questionPasswords,
      adminPassword: this.state.adminPassword,
      teams: this.state.teams
    });
    post('/api/copy-images', {
      originalGameCode: this.state.originalGameCode,
      newGameCode: this.state.gameCode
    });
    this.setState({
      questionPasswords: questionPasswords,
      gameCode: this.state.gameCode,
      submitted: true,
    });
  };

  download = (data, filename, type) => {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      var a = document.createElement("a"),
          url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };

  downloadAsTxt = () => {
    let data = `You have made a new game: ${this.state.name}!\nThe code is: ${
        this.state.gameCode
    } \nThe admin password is: ${
        this.state.gameCode + this.state.adminPassword
    } \nHere are all of the teams in this game: \n${this.state.teams.join(
        ", "
    )} \nHere are your questions and their passwords:`;
    for (let i = 0; i < this.state.questions.length; i++) {
      const question = this.state.questions[i];
      data += `\nQuestion ${String.fromCharCode(i + "A".charCodeAt(0))} \n\nStudents get ${
          this.state.times[i]
      } minutes to answer this question \nThe password for this question is ${
          this.state.questionPasswords[i]
      } \n
      `;
      for (let j = 0; j < question.length; j++) {
        data += ` Part ${j + 1}: \nQuestion: ${question[j]} \nThis is worth ${
            this.state.points[i][j]
        } points. \n`;
      }
    }
    this.download(data, `${this.state.name}-${this.state.gameCode}-info.txt`, "txt");
  };

  render() {
    if (this.state.submitted) {
      return (
          <>
            You have cloned the game! The code is {this.state.gameCode}. The admin code is{" "}
            {this.state.gameCode + this.state.adminPassword}.
            <div> Here are all of the teams in this game: {this.state.teams.join(", ")} </div> Here
            are your questions and their passwords:
            {this.state.questions.map((question, i) => (
                <div key={`question-overview-${i}`}>
                  <h2> Question {String.fromCharCode(i + "A".charCodeAt(0))}: </h2>
                  <p> Students get {this.state.times[i]} minutes to answer this question </p>
                  <p> The password for this question is {this.state.questionPasswords[i]} </p>
                  {question.map((singleQuestion, j) => (
                      <div key={`question-${i}-part-${j}`}>
                        <h4> Part {j + 1}: </h4>
                        <p> Question: </p>
                        {singleQuestion.split(/\r?\n/g).map(text => {
                          return (
                              <p>{text}</p>
                          )
                        })}
                        <p> This is worth {this.state.points[i][j]} points. </p>
                      </div>
                  ))}
                </div>
            ))}
            <button onClick={this.downloadAsTxt}> Download as a .txt file </button>
          </>
      );
    }

    return (
        <>
          <div>
            <span className="standard-text">Teams in this game (one per line): </span>
            <textarea className="large-text-box team-list-box" onChange={this.handleChangeTeams} />
          </div>
          <div className="u-flex-justifyCenter top-margin">
            <span className="NewGame-button" onClick={this.handleSubmit}>
              <span className="button-text">Set Game </span>
            </span>
          </div>
        </>
    )
  }
}

export default CloneGamePage;
